<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="100px" label-position="left" :model="form" ref="form" :rules="rule">
      <el-form-item label="调整类型">
        <el-radio-group v-model="form.kind">
          <el-radio-button :label="1">增加</el-radio-button>
          <el-radio-button :label="0">减去</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="价格调整">
        <el-input-number v-model="form.price" :min="0.01" :step="0.01" :precision="2"></el-input-number>
      </el-form-item>
      <el-form-item label="备注" prop="reason">
        <el-input type="textarea" :rows="5" v-model="form.reason"></el-input>
      </el-form-item>
      <el-form-item style="display: flex;align-items: center;justify-content: flex-end">
        <el-button @click="close">
          取消
        </el-button>
        <el-button type="primary" @click="handelConfirm('form')">
          确定
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {apiAdjustOrderPrice} from "@/request/api";

export default {
  inheritAttrs: false,
  name: "priceAdjustment",
  props:{
    id: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      form: {
        price: 0,
        reason: '',
        kind: 1,
      },
      rule:{
        reason: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],
      }
    }
  },
  methods:{
    onClose() {
    },
    onOpen(){
      this.initForm()
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    initForm(){
      this.form= {
        price: 0,
            reason: '',
            kind: 1,
      }
    },
    handelConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiAdjustOrderPrice({...this.form,id:this.id}).then(res =>{
            if(res.code == 200){
              this.close()
            }else{
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          })
        } else {
          return false
        }
      })

    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-top: 10px;
}
</style>