<template>
  <div class="page">
    <template v-if="Voucher">
      <h2>转账记录</h2>
      <el-table :data="orderVoucher" border style="margin-bottom: 20px">
        <el-table-column label="时间">
          <template slot-scope="scope">
            <span>{{scope.row.create_at | formatDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="转账信息">
          <template slot-scope="scope">
            <div style="display: flex;align-items: center">
              <el-image
                  style="width: 50px; height: 50px;margin-right: 10px"
                  :src="scope.row.picture"
                  :preview-src-list="[scope.row.picture]">
              </el-image>
              <div style="display: flex;flex-direction: column;justify-content: center;">
                <span style="margin: 0">{{scope.row.notes}}</span>
                <span style="margin: 0;color: #409EFF">{{scope.row.state_text}}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="转账方式" prop="pay_name"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" style="margin-right: 10px" @click="confirmVoucherForm.voucher_id = scope.row.voucher_id;confirmVoucherDialog=true" v-if="!scope.row.state">确认转账</el-link>
            <el-link :underline="false" type="danger"  @click="delVoucher(scope.row.voucher_id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="确认转账" :visible.sync="confirmVoucherDialog" width="35%">
        <el-form :model="confirmVoucherForm" label-position="top">
          <el-form-item label="订单支付状态">
            <el-select v-model="confirmVoucherForm.pay_state">
              <el-option label="未付款" value="0"></el-option>
              <el-option label="已付款" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邮箱" v-show="confirmVoucherForm.pay_state == '0'">
            <el-input v-model="confirmVoucherForm.email_content" type="textarea" :rows="4"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="confirmVoucherDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmVoucher()">确 定</el-button>
        </div>
      </el-dialog>
    </template>
    <el-row :gutter="24">
      <el-col :span="10">
        <div style="display: flex">
          <h2>订单详情&nbsp;&nbsp;</h2>
          <h2 style="color: rgb(245, 108, 108)"> ({{orderDetail.order_state_text}})</h2>
        </div>
        <div class="orderDetail">
          <span>订单编号：{{orderDetail.order_sn}}</span>
          <span>订单类型：{{orderDetail.kind_text}}</span>
<!--          <span>营销活动：@TODO</span>-->
          <span>订单来源：{{orderDetail.source}}</span>
          <span>付款方式：{{orderDetail.pay_channel}}</span>
          <span>买家：{{orderDetail.user_info.id}}-{{orderDetail.user_info.name}}</span>
          <span>收货人：{{orderDetail.address_info.real_name}}</span>
          <span>收货地址：{{orderDetail.address_info.country}}{{orderDetail.address_info.province}}{{orderDetail.address_info.city}}{{orderDetail.address_info.area}}{{orderDetail.address_info.street}}</span>
          <span>收货电话：{{orderDetail.address_info.real_phone}}</span>
          <span>收货Email：{{orderDetail.user_info.email}}</span>
          <span>物流单号：{{ orderDetail.logistics_number }}</span>
          <span>物流最新信息：{{ orderDetail.logistics_status }}</span>
          <span>物流公司：{{ orderDetail.logistics_company }}</span>
          <span v-if="orderDetail.remarks">买家备注：{{orderDetail.remarks}}</span>
          <span v-if="orderDetail.seller_remark">卖家备注：{{orderDetail.seller_remark}}</span>
        </div>
<!--        <h2>结算详情</h2>-->
<!--        <div class="payDetail">-->
<!--          <span>店铺结算金额：</span>-->
<!--          <span>平台结算金额：</span>-->
<!--          <span>结算状态：</span>-->
<!--        </div>-->
      </el-col>
      <el-col :span="10" :offset="2">
        <el-card class="box-card" v-if="!Voucher">
          <el-button type="primary"  @click="setSellRemark">设置备注</el-button>
          <el-button type="primary"  @click="showSetAddress = true">修改地址</el-button>
          <setAddressF :visible.sync="showSetAddress" :adressInfo="orderDetail.address_info" title="修改地址" :id="id" @confirm="getOrderDetail"></setAddressF>
          <el-button type="primary"  @click="cancelOrder" v-if="orderDetail.order_state == 1 ||orderDetail.order_state == 2">取消订单</el-button>
          <el-button type="primary"  @click="showPriceAdjustment = true" v-if="orderDetail.order_state == 1">调整价格</el-button>
          <priceAdjustment :visible.sync="showPriceAdjustment" title="调整价格" :id="id"></priceAdjustment>
          <el-button type="primary"  @click="showSetOrderState = true">调整订单状态</el-button>
          <setOrderState :visible.sync="showSetOrderState" title="调整订单状态" :id="id" :orderStateText="orderDetail.order_state_text" :email="orderDetail.user_info.email" @confirm="getOrderDetail"></setOrderState>
          <el-button type="primary"  @click="sendInvoice">发送Invoice</el-button>
          <el-button type="primary"  @click="showSendEmail = true">发送Email</el-button>
          <sendEmail :visible.sync="showSendEmail" title="发送Email" :id="id"></sendEmail>
          <el-button type="primary"  @click="showPrintGoodsList = true">打印配货单</el-button>
          <printGoodsList :visible.sync="showPrintGoodsList" title="打印配货单" :id="id"></printGoodsList>
          <el-button type="primary"  @click="showLogisticsInformation = true">查询物流信息</el-button>
          <logisticsInformation :visible.sync="showLogisticsInformation" title="查询物流信息" :id="id"></logisticsInformation>
        </el-card >
        <el-card class="box-card" v-if="Voucher">
          <el-button type="primary"  @click="backOrderDetail">返回订单详情</el-button>
        </el-card>
      </el-col>
    </el-row>

    <h2>商品列表和费用信息 {{orderDetail.currency}}</h2>
    <el-table :data="orderDetail.goods_list" border>
      <el-table-column label="商品">
        <template slot-scope="scope">
          <div class="goodsInfo">
            <el-image class="goodsImg" :src="scope.row.pic" fit="contain"/>
            <div class="infoView">
              <span>{{scope.row.goods_name}}</span>
              <span>{{scope.row.sku}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="单价" prop="unit_price"/>
      <el-table-column label="数量" prop="nums"/>
      <el-table-column label="小记" prop="subtotal"/>
      <el-table-column label="优惠" prop="discount_amount"/>
      <el-table-column label="实付" prop="final_price"/>
    </el-table>
    <div class="costDetail">
      <span>商品总额：{{orderDetail.product_amount_total}}</span>
      <span>配送费用：{{ orderDetail.logistics_fee }}</span>
      <span style="color: #F56C6C">订单共{{ orderDetail.goods_list.length }}件商品，总计：{{ orderDetail.order_amount_total }}</span>
    </div>
    <h2>支付记录</h2>
    <el-table :data="orderDetail.pay_log" border>
      <el-table-column label="支付方式" prop="pay_name">

      </el-table-column>
      <el-table-column label="支付状态" prop="pay_state">

      </el-table-column>
      <el-table-column label="支付金额" prop="account">

      </el-table-column>
<!--      <el-table-column label="货币">-->

<!--      </el-table-column>-->
      <el-table-column label="流水单号" prop="order_sn">

      </el-table-column>
      <el-table-column label="支付时间" >
        <template slot-scope="scope">
          {{scope.row.pay_time | formatDate}}
        </template>
      </el-table-column>
      <el-table-column label="备注">

      </el-table-column>
    </el-table>
    <template v-if="orderDetail.return.length > 0">
      <h2>售后记录</h2>
      <el-table :data="orderDetail.return" border>
        <el-table-column label="是否收货" prop="is_receive" >

        </el-table-column>
        <el-table-column label="退款类型" prop="kind">

        </el-table-column>
        <el-table-column label="申请时间">
          <template slot-scope="scope">
            {{scope.row.create_at | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="state">

        </el-table-column>
        <el-table-column label="客服备注" prop="remark">

        </el-table-column>
        <el-table-column label="退款金额" prop="refund_money">

        </el-table-column>
        <el-table-column label="退款积分" prop="refund_gift">

        </el-table-column>
        <el-table-column label="退款时间">
          <template slot-scope="scope">
            {{scope.row.refund_at | formatDate}}
          </template>
        </el-table-column>
      </el-table>
    </template>

    <h2>操作日志</h2>
    <el-table :data="orderDetail.history" border>
      <el-table-column label="操作" prop="descr">

      </el-table-column>
      <el-table-column label="操作人" prop="op">

      </el-table-column>
      <el-table-column label="订单状态" prop="state">

      </el-table-column>
      <el-table-column label="操作端口" prop="channel">

      </el-table-column>
      <el-table-column label="操作时间">
        <template slot-scope="scope">
          {{scope.row.create_at | formatDate}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import setRemark from "@/views/order/components/setRemark";
import setAddressF from "@/views/order/components/setAddressF";
import cancelOrder from "@/views/order/components/cancelOrder";
import priceAdjustment from "@/views/order/components/priceAdjustment";
import setOrderState from "@/views/order/components/setOrderState";
import sendInvoice from "@/views/order/components/sendInvoice";
import sendEmail from "@/views/order/components/sendEmail";
import printGoodsList from "@/views/order/components/printGoodsList";
import logisticsInformation from "@/views/order/components/logisticsInformation";
import {
  apiCancelOrder,
  apiGetOrderDetail,
  apiSendOrderInvoice,
  apiSetOrderRemark,
  apiGetOrderVoucher,
  apiDelVoucher,
  apiApproveVoucher
} from "@/request/api";
export default {
  name: "orderDetail",
  components:{
    setRemark,
    setAddressF,
    cancelOrder,
    priceAdjustment,
    setOrderState,
    sendInvoice,
    sendEmail,
    printGoodsList,
    logisticsInformation
  },
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  data(){
    return{
      showRemark:false,
      showSetAddress:false,
      showCancelOrder:false,
      showPriceAdjustment:false,
      showSetOrderState:false,
      showSendInvoice:false,
      showSendEmail:false,
      showPrintGoodsList:false,
      showLogisticsInformation:false,
      id:this.$route.query.id,
      Voucher:this.$route.query.Voucher == 'true'?true:false,
      orderDetail:{},
      orderVoucher: [],
      confirmVoucherDialog:false,
      confirmVoucherForm: {
        voucher_id: null,
        pay_state: '0',
        email_content: '',
        email_format: 1
      }
    }
  },
  mounted() {
    this.getOrderDetail();
    if (this.Voucher) {
      this.getOrderVoucher()
    }
  },
  methods:{
    getOrderDetail(){
      apiGetOrderDetail({id:this.id}).then(res=>{
        if(res.code == 200){
          this.orderDetail = res.data;
        }
      })
    },
    getOrderVoucher() {
      apiGetOrderVoucher({order_id:this.id}).then(res => {
        if(res.code == 200) {
          this.orderVoucher = res.data
        }
      })
    },
    setSellRemark(){
      let that = this
      this.$prompt('备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern:  /\S/,
        inputErrorMessage: '备注内容不能为空',
        inputType: 'textarea',
        inputValue: that.orderDetail.seller_remark
      }).then(({ value }) => {
        apiSetOrderRemark({id:that.id,remark:value}).then(res => {
          if(res.code == 200){
            that.orderDetail.seller_remark = value;
          }
        })
      })
    },
    cancelOrder(){
      this.$confirm('是否取消订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(()=>{
        apiCancelOrder({id:this.id}).then(res=>{
          if(res.code == 200){
            this.$message.success('取消订单成功');
            this.getOrderDetail();
          }else{
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      })

    },
    delVoucher(voucherId) {
      this.$confirm('确认删除该转账记录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiDelVoucher({voucher_id: voucherId}).then(res => {
          if(res.code == 200) {
            this.getOrderVoucher()
          }
        })
      })
    },
    sendInvoice(){
      this.$confirm('是否发送Invoice', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(()=>{
        apiSendOrderInvoice({id:this.id}).then(res=>{
          if(res.code == 200){
            this.getOrderDetail();
          }else{
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      })
    },
    confirmVoucher() {
      apiApproveVoucher(this.confirmVoucherForm).then(res => {
        if(res.code == 200){
          this.confirmVoucherForm = {
            voucher_id: null,
            pay_state: '0',
            email_content: '',
            email_format: 1
          }
          this.confirmVoucherDialog = false
          this.getOrderVoucher()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    backOrderDetail() {
      this.$router.replace({
        path:'/order/orderDetail',
        query:{
          id:this.id,
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.orderDetail,.payDetail,.costDetail {
  display: flex;
  flex-direction: column;
}
.costDetail {
  align-items: flex-end;
}
span{
  font-size: 14px;
  color: #606266;
  margin-top: 10px;
}
.el-button {
  margin: 5px;
}
.goodsInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.goodsInfo .goodsImg {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}
.goodsInfo .infoView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 18px;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
}
.goodsInfo .infoView span {
  margin:0;
}

</style>
