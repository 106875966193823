<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="100px" label-position="left">
      <el-form-item label="当前订单">
        <span>{{orderStateText}}</span>
      </el-form-item>
      <el-form-item label="变更类型">
        <el-radio-group v-model="order.state">
          <el-radio-button :label="0">未支付</el-radio-button>
          <el-radio-button :label="1">已支付</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span style="display: block;margin-top: 10px">说明：</span>
    <span style="display: block;margin-top: 10px">1.当未付款订单变更为已付款时，系统将为该订单添加一条付款方式为（后台支付）的付款记录</span>
    <span style="display: block;margin-top: 10px">2.当已付款订单变更为未付款时，系统将自动作废该订单所有已支付成功的支付记录</span>
    <span style="display: block;margin-top: 10px">2.如频繁切换付款状态，按说明第1，2条重复执行</span>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiAdjustOrderPay} from "@/request/api";

export default {
  inheritAttrs: false,
  name: "setOrderState",
  data() {
    return {
      order: {
        state: 0
      }
    };
  },
  props:{
    id: {
      type: Number,
      default: null
    },
    orderStateText: {
      type: String,
      default: ''
    }
  },
  methods:{
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      apiAdjustOrderPay({id:this.id,state:this.order.state}).then(res => {
        if(res.code == 200){
          this.$emit('confirm',true)
          this.close()
        }else{
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-top: 10px;
}
</style>