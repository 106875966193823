<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form  label-width="100px" label-position="left" :rules="rule" :model="formAdressInfo" ref="formAdressInfo">
      <el-form-item label="收货人" prop="real_name">
        <el-input v-model="formAdressInfo.real_name" placeholder="请输入收货人姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="real_phone">
        <el-input v-model="formAdressInfo.real_phone" maxlength="11" placeholder="请输入收货人手机号码"></el-input>
      </el-form-item>
      <el-form-item label="所在国家" prop="country_id">
        <el-select
            v-model="formAdressInfo.country_id"

            placeholder="请选择所在地区"
            @change="handleCountryChange"
        >
          <el-option v-for="item in countryList" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在地区" prop="code" v-if="areaList.length > 0">
        <el-cascader
            v-model="formAdressInfo.code"
            :options="areaList"
            :props="{ expandTrigger: 'hover',value:'code',label:'name',children:'children',emitPath:false }"
            placeholder="请选择所在地区"
            @change="handleAreaChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="street">
        <el-input v-model="formAdressInfo.street" placeholder="请输入详细地址"></el-input>
      </el-form-item>
        <el-form-item style="display: flex;align-items: center;justify-content: flex-end">
          <el-button @click="close">
            取消
          </el-button>
          <el-button type="primary" @click="handelConfirm('formAdressInfo')">
            确定
          </el-button>
        </el-form-item>
    </el-form>

  </el-dialog>
</template>

<script>
import {apiGetCountryList, apiGetRegionList, apiModifyOrderAddress} from "@/request/api";

export default {
  inheritAttrs: false,
  name: "setAddressF",
  props:{
    id: {
      type: Number,
      default: null
    },
    adressInfo:{
      type: Object,
      default: {}
    }
  },
  data(){
    function validatePhone(rule, value,callback) {
      const reg =/^[1][3-9][0-9]{9}$/;
      if(value==''||value==undefined||value==null){
        callback();
      }else {
        if ((!reg.test(value)) && value != '') {
          callback(new Error('请输入正确的电话号码'));
        } else {
          callback();
        }
      }
    }
    return{
      countryList: [],
      areaList:[],
      formAdressInfo:JSON.parse(JSON.stringify(this.adressInfo)),
      rule:{
        real_name:[{ required: true, message: '请输入收货人', trigger: 'blur' }],
        street:[{ required: true, message: '详细地址', trigger: 'blur' }],
        country_id:[{ required: true, message: '请选择国家', trigger: 'blur' }],
        code:[{ required: true, message: '请选择所在地区', trigger: 'blur' }],
        real_phone:[{ validator: validatePhone, trigger: 'blur' }],
      }
    }
  },
  methods:{
    onClose() {
    },
    onOpen(){
      this.formAdressInfo=JSON.parse(JSON.stringify(this.adressInfo))
      this.getCountryList()
      this.getAreaList()
    },
    close(e) {
      this.$emit('update:visible', false)

    },
    handelConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiModifyOrderAddress({
            real_name: this.formAdressInfo.real_name,
            real_phone: this.formAdressInfo.real_phone,
            country_id: this.formAdressInfo.country_id,
            code: this.formAdressInfo.code,
            street: this.formAdressInfo.street,
            id:this.id
          }).then(res=>{
            if(res.code == 200){
              this.$emit('confirm',true)
              this.close()
            }else{
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          })
        } else {
          return false;
        }
      });

    },
    getCountryList(){
      apiGetCountryList().then(res=>{
        this.countryList = res.data
      })
    },
    getAreaList(){
      apiGetRegionList({id:this.formAdressInfo.country_id}).then(res => {
        this.areaList = res.data
        this.formAdressInfo.code = ''
        if(res.data.length == 0){
          this.formAdressInfo.code = 0
        }
      })
    },
    handleCountryChange(){
      this.getAreaList()
    }
  }

}
</script>

<style scoped>
.el-form-item{
  margin-top: 10px;
}
</style>