<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-timeline>
        <el-scrollbar style="height: 45vh">
          <template v-for="event in logiInfo.track">
            <el-timeline-item
                v-for="(item, index) in event.events"
                :key="index"
                :timestamp="'Local Time：'+item.time_iso +'    UTC：'+item.time_utc"
                placement="top">
              <p>{{item.location}}</p>
              <p>{{item.description}}</p>
            </el-timeline-item>
          </template>

        </el-scrollbar>
    </el-timeline>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiGetOrderLogiTrack} from "@/request/api";

export default {
  inheritAttrs: false,
  name: "logisticsInformation",
  props:{
    id: {
      type: Number,
      default: null
    },
  },
  data(){
    return{
      logiInfo:{}
    }
  },

  methods:{
    onOpen() {
      this.getLogiTrack()
    },
    getLogiTrack(){
      apiGetOrderLogiTrack({id:this.id}).then(res => {
        if(res.code == 200){
          this.logiInfo = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
