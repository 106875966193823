<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
      width="1340px"
  >
    <el-form label-width="100px" label-position="left" :model="form" ref="form" :rules="rule">
      <el-form-item label="邮件标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入邮件标题"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <tinymce v-model="form.content" placeholder="请输入内容" :height="600" />
      </el-form-item>
      <el-form-item style="display: flex;align-items: center;justify-content: flex-end">
        <el-button @click="close">
          取消
        </el-button>
        <el-button type="primary" @click="handelConfirm('form')">
          确定
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {apiSendOrderEmail} from "@/request/api";

export default {
  inheritAttrs: false,
  name: "sendEmail",
  props:{
    id: {
      type: Number,
      default: null
    },
    email:{
      type:String,
      default: ''
    }
  },
  data(){
    return{
      rule:{
        title: [
          { required: true, message: '请输入邮件标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      form:{
        title:'',
        content:'',
      },

    }
  },
  methods:{
    onClose() {
    },
    onOpen(){
      this.init()
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    init(){
      form={
        title:'',
        content:'',
      }
    },
    handelConfirm(formName) {
      var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$/
      if(!reg.test(this.email)) {
        this.$message({
          message: '无有效邮箱地址',
          type: 'error'
        });
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiSendOrderEmail({id:this.id,...this.form}).then(res=>{
            if(res.code == 200){
              this.close()
            }else{
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>